@import './../../index.scss';

.pageContainer {
	font-family: $font-primary;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.8;
	color: lighten($brand-darker, 20%);
	color: #7f7f7f;
	background: $brand-bg-color;
	height: 100%;
	position: relative;
}
a {
	color: $brand-primary;
	@include transition(.5s);
	&:hover, &:active, &:focus {
		color: $brand-primary;
		outline: none;
	}
}
p {
	margin-bottom: 1.5em;
}

#mainNavbar {
	background-color: #220c14;
	.nav-link {
		color: white;
	}
}
.mySwiper {
	margin-top: 20px;
}

h1, h2, h3, h4, h5, h6 {
	color: $brand-black;
	font-family: $font-primary;
	font-weight: 400;
}
::-webkit-selection {
  color: $brand-body-color;
  background: $brand-selection-color;
}

::-moz-selection {
  color: $brand-body-color;
  background: $brand-selection-color;
}

::selection {
  color: $brand-body-color;
  background: $brand-selection-color;
}


.section-heading {
	float: left;
	width: 100%;
	padding-bottom: 50px;
	margin-bottom: 50px;
	clear: both;
	h2 {
		font-size: 50px;
		font-weight: 300;
		color: #444;
		text-transform: uppercase;
		position: relative;
		display: block;
		padding-bottom: 20px;
		line-height: 1.5;
		&:after {
			content: "";
			position: absolute;
			display: block;
			width: 80px;
			height: 2px;
			background: $brand-primary;
			left: 50%;
			margin-left: -40px;
			bottom: 0;
		}
		@media screen and (max-width: $screen-sm) {
			font-size: 30px;
		}
	}
	h3 {
		font-weight: 300;
		line-height: 1.5;
		color: #929292;
		@media screen and (max-width: $screen-sm) {
			font-size: 24px!important;
			line-height: 34px;
		}
	}
}

.btn {
	@include transition(.3s);
}

.swiper {
	width: 1440px;
	height: 810px;
  }
  
  .swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	height: 100%;
	display: flex!important;;
	justify-content: center;
	align-items: center;
  }
  
  .swiper-slide img {
	display: block;
	width: 100%;
	height: auto;
	object-fit: cover;
  }
  
  @media (max-width: 768px) {
	.swiper {
		width: 100%;
	  height: auto;
	}
	 
	  
	.swiper-slide img {
	  width: 100%;
	  height: auto;
	}
  }

.swiper-button-next {
	margin-right: 20px;
}

.swiper-button-prev {
	margin-left: 20px;
}

#fh5co-services,
#fh5co-about-us,
#fh5co-features,
#fh5co-testimonials,
#fh5co-pricing,
#fh5co-custom-develop,
#fh5co-projects {
	padding: 7em 0;
	@media screen and (max-width: $screen-xs) {
		padding: 5em 0;
	}
}
#fh5co-testimonials {
	background: $brand-primary;
	.section-heading {
		h2 {
			color: rgba(255,255,255,1);
			&:after {
				background: rgba(255,255,255,.2);
			}
		}
		.subtext {
			h3 {
				color: rgba(255,255,255,.5);
			}
		}
	}
	.box-testimony {
		margin-bottom: 2em;
		float: left;
		a {
			color: rgba(255,255,255,.5);
			&:hover, &:focus, &:active {
				color: rgba(255,255,255,1);
				text-decoration: none;
			}
		}
		blockquote {
			padding-left: 0;
			border-left: none;
			padding: 40px;
			background: $brand-white;
			
			position: relative;
			@include border-radius(4px);
			&:after {
			   content: "";
			   position: absolute;
			   top: 100%;
			   left: 40px;
			   border-top: 15px solid black;
			   border-top-color: $brand-white; 
			   border-left: 15px solid transparent;
			   border-right: 15px solid transparent; 
			}
			.quote {
				position: absolute;
				top: 0;
				left: 10px;
				text-align: center;
				font-size: 30px;
				line-height: 0;
				display: table;
				color: $brand-white;
				width: 56px;
				height: 56px;
				margin-top: -30px;
				background: $brand-primary;
				@include border-radius(50%);
				> span {
					display: table-cell;
					vertical-align: middle;
				}
			}
			p {
				font-style: italic;
			}
		}
		.author {
			line-height: 20px;
			color: rgba(255,255,255,1);
			font-size: 20px;
			margin-left: 20px;
			.subtext {
				display: block;
				color: rgba(255,255,255,.5);
				font-size: 16px;
			}
		}
	}
}
#fh5co-pricing {
	.price-box {
	    border: 2px solid #ECEEF0;
	    text-align: center;
	    padding: 30px;
	    @include border-radius(5px);
	    margin-bottom: 40px;
	    position: relative;
	    &.popular {
	    	border: 2px solid $brand-primary;
	    	.popular-text {
	    		top: 0;
	    		left: 50%;
	    		margin-left: -54px;
	    		margin-top: -2em;
				position: absolute;
				padding: 4px 20px;
				background: $brand-primary;
				color: $brand-white;
				@include border-radius(4px);
				&:after {
				   content: "";
				   position: absolute;
				   top: 100%;
				   left: 50%;
				   margin-left: -10px;
				   border-top: 10px solid black;
				   border-top-color: $brand-primary; 
				   border-left: 10px solid transparent;
				   border-right: 10px solid transparent; 
				}
	    	}
	    }
	}
	.pricing-plan {
		margin: 0;
		padding: 0;
		font-size: 15px;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-weight: 700;
	}
	.price {
		font-size: 50px;
		color: $brand-black;
		.currency {
			font-size: 20px;
			top: -1.2em;
		}
		small {
			font-size: 16px;
		}
	}
	.pricing-info {
		padding: 0;
		margin: 0 0 30px 0;
		li {
			padding: 0;
			margin: 0;
			list-style: none;
			text-align: center;
		}
	}
}

#fh5co-our-services,
#fh5co-projects{
	background: #f4f4f4;
}
#fh5co-services {
	
	.box {
		// min-height: 360px;
		opacity: 0;
		padding-top: 30px;
		background: $brand-white;
		text-align: center;
		color: #7f7f7f;
		margin-bottom: 70px;
		position: relative;
		@media screen and (max-width: $screen-md) {
			min-height: inherit;
			margin-bottom: 60px;
		}
		.icon {
			width: 85px;
			height: 85px;
			margin: 0 auto;
			// top: -70px;
			top: 0;
			margin-top: -70px;
			margin-bottom: 30px;
			position: relative;
			@include border-radius(50%);
			display: table;
			text-align: center;
			> span {
				display: table-cell;
				vertical-align: middle;
				i {
					font-size: 40px;
					color: $brand-white!important;
					margin: 0;
					padding: 0;
				}
			}
			&.colored-1 {
				background: #2aaf67;
			}
			&.colored-2 {
				background: #00bff3;
			}
			&.colored-3 {
				background: #f26522;
			}
			&.colored-4 {
				background: #e52b50;
			}
			&.colored-5 {
				background: #2fc5cc;
			}
			&.colored-6 {
				background: #6173f4;
			}
		}
		.step-number {
			font-size: 16px;
			display: block;
			color: #d1d1d1;
			letter-spacing: 2px;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 10px;
		}
		h3 {
			margin: 0 0 20px 0;
			padding: 0;
			font-weight: 400;
			font-size: 20px;
			color: $brand-darker;
		}
	}
}
#fh5co-about-us {
	background: #f4f4f4;
}

#fh5co-features {
	padding: 7em 0;
	background: #f4f4f4;
	@media screen and (max-width: $screen-sm) {
		padding: 4em 0;
	}
	.fh5co-service {
		opacity: 0;
		margin-bottom: 30px;
		.fh5co-icon {
			float: left;
			width: 20%;

			i {
				font-size: 40px;
				color: $brand-primary;
			}
		}
		.fh5co-desc {
			width: 80%;
			float:right;
			h3 {
				margin-top: 0;
				font-size: 18px;
			}
		}
	}
}
#fh5co-projects {
	.fh5co-projects-item {
		opacity: 0;
		background: $brand-white;
		float: left;
		margin-bottom: 30px;
		position: relative;
		overflow: hidden;
		@include flex;
		@include border-radius(4px);
		@media screen and (max-width: $screen-xs) {
			display: block!important;
		}
		.fh5co-projects-text,
		.fh5co-projects-img {
			width: 50%;
			@media screen and (max-width: $screen-xs) {
				height: inherit;
				float: none!important;
				width: 100%;
				display: block!important;
			}
		}
		.fh5co-projects-text {
			float: left;
			padding: 20px;
			.fh5co-projects-title {
		
				margin: 0 0 20px 0;
				padding: 0 0 10px 0;
				font-weight: 400;
				font-size: 20px;
				color: $brand-darker;
				position: relative;
				.fh5co-border {
					position: absolute;
					left: 0;
					bottom: 0;
					height: 2px;
					width: 30px;
					background: $brand-primary;
				}
			}
		}
		.fh5co-projects-img {
			position: absolute;
			right: 0;
			bottom: 0;
			top: 0;
			background-size: cover;
			@media screen and (max-width: $screen-xs) {
				height: 200px;
				left: 0;
				top: 0;
				position: relative;
				width: 100%;
			}
		}
	}
}
.fh5co-nav-toggle {
  display: none;
  cursor: pointer;
  text-decoration: none;
  border-bottom: none!important;
  @media screen and (max-width: $screen-md) {
  	display: inline-block;
  }
}


#footer {
	padding: 50px 0;
	color: #7f7f7f;
	// color: #d6d6d6;
	.copyright {
		margin-bottom: 0px;
		padding-bottom: 0;
	}
}


.btn {
	text-transform: uppercase;
	letter-spacing: 2px;
	@include transition(.3s);
	&.btn-primary {
		background: $brand-primary;
		color: $brand-white;
		border: none!important;
		border: 2px solid transparent!important;
		&:hover, &:active, &:focus {
			box-shadow: none;
			background: $brand-primary;
		}
	}
	&:hover, &:active, &:focus {
		background: #393e46!important;
		color: $brand-white;
		outline: none!important;
	}
	&.btn-default {
		&:hover, &:focus, &:active {
			border-color: transparent;
		}
	}
}

.social {
	padding: 0;
	margin: 0;
	display: inline-block;
	position: relative;
	width: 100%;
	li {
		list-style: none;
		padding: 0;
		margin: 0;
		display: inline-block;
		a {
			font-size: 16px;
			display: table;
			width: 40px;
			height: 40px;
			margin: 0 4px;

			i {
				display: table-cell;
				vertical-align: middle;
			}
			&:hover, &:active, &:focus {
				text-decoration: none;
				border-bottom: none;
			}
			&.social-box {
				background: $brand-primary;
				color: $brand-white;
			}
			&.social-circle {
				background: $brand-primary;
				color: $brand-white;
				@include border-radius(50%);
			}
			&:hover {
				background: #393e46!important;
			}

		}
	}
	&.social-box {
		a {
			background: $brand-primary;
			color: $brand-white;
		}
	}
	&.social-circle {
		a {
			background: $brand-primary;
			color: $brand-white;
			@include border-radius(50%);
		}
	}
}


// Components

.js .to-animate,
.js .to-animate-2,
.js .single-animate {
	opacity: 0;
}


// Helper Class

.row-bottom-padded-sm {
	padding-bottom: 2em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 2em;
	}	
}
