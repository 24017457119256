@font-face {
  font-family: 'DrukCyr-Medium';
  src: url('./assets/fonts/DrukCyr/DrukCyr-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('./assets/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./assets/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
}

$font-primary: 'Source Sans Pro', Arial, sans-serif;
$grid-gutter-width: 40px !default; 
$border-radius-base:  4px !default;
$padding-base-vertical: 14px !default;

$brand-primary: #6173f4 !default;

$brand-white: #fff;
$brand-black: #000;
$brand-darker: #444;
$brand-gray: #ccc;
$brand-lighter: #e9e9e9;
$brand-body-color: #818892;
$brand-selection-color: #f9f6f0;
$brand-overlay-color: #3b3d40;
$brand-bg-color: $brand-white;

$input-border-focus:  $brand-primary !default;
$form-group-margin-bottom:       30px !default;

$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;

$screen-phone:               $screen-xs-min !default;

$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


// Mixin
@mixin transition($transition) {
    -moz-transition:    $transition ease-in;
    -o-transition:      $transition ease-in;
    -webkit-transition: $transition ease-in;
    -ms-transition: 	$transition ease-in;
    transition:         $transition ease-in;
}
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}
@mixin flex() {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@font-face {
	font-family: 'icomoon';
	src:url('./assets/fonts/icomoon/icomoon.eot?srf3rx');
	src:url('./assets/fonts/icomoon/icomoon.eot?srf3rx#iefix') format('embedded-opentype'),
		url('./assets/fonts/icomoon/icomoon.ttf?srf3rx') format('truetype'),
		url('./assets/fonts/icomoon/icomoon.woff?srf3rx') format('woff'),
		url('./assets/fonts/icomoon/icomoon.svg?srf3rx#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin icomoon() {
	
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
}


html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
